function Footer() {
  return (
    <div className="footer">
      <span>17570 NC HWY 902<br/>Bear Creek, NC 27207</span>
      <span>Phone: <a href="tel:9198372021">919-837-2021</a></span>
      <span>Email: <a href="mailto:caslabsllc@gmail.com">caslabsllc@gmail.com</a></span>
    </div>
  )
}

export default Footer;